import React from "react"
import { graphql, Link } from "gatsby"
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Layout from '../components/shared/layout'; 
import SEO from '../components/shared/seo';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  
  return (
    <Layout>

      <SEO title={frontmatter.title} />
      
      <Container>
        <Article>
          <Backlink to={'/articles'}>&larr; articles</Backlink>
          <h1>{frontmatter.title}</h1>
          <Date>{frontmatter.date}</Date>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Article>
      </Container>
    </Layout>
  )
}

const Container = styled.div`${tw`max-w-lg m-auto py-16`}`
const Article = styled.div `${tw`leading-loose`}`
const Backlink = styled(Link)`${tw`text-sm text-black no-underline`}`
const Date = styled.time`${tw`italic`}`

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        path
        title
      }
    }
  }
`